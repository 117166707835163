import React from "react"
import { Page } from "../shared/Page/Page"
import styled from "styled-components"
import { Link } from "gatsby"
import { H1, H2, P } from "../shared/Ui/Typography/Typography"
import { FlatButton } from "../shared/Ui/FlatButton/FlatButton"

const Container = styled.div`
    padding: 60rem 0;
    text-align: center;
    width: 100%;
`

const Page404 = ({ location }) => {
    return (
        <Page constrained disableCta location={location}>
            <Container>
                <H1>404 - Page not found</H1>
                <H2>That's bad news...</H2>
                <P>
                    We looked everywhere (we really did!) but couldn't find the
                    page you requested.
                </P>

                <P>Maybe you'll find what you're looking for back home?</P>

                <Link to={"/"}>
                    <FlatButton size={"large"} colo={"primary"}>
                        Return home
                    </FlatButton>
                </Link>
            </Container>
        </Page>
    )
}

export default Page404
